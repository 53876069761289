import React from "react"
import Guide from "../../../components/guide2020"

const IndexPage = ({data}) => (
  <Guide
    Year={"2020"}
    Section={"innbo"}
    ProductName={"innboforsikring"}
    Entity={"innbo"}
    Attributes={38}
    Stars5={{
      need: "For deg som vil være aller best sikret",
      description: 'De aller beste innboforsikringene - 5 defaqto stjerner',
      terms: [
        {t: "Tyveri og skadeverk på innboet i bod",c: "Må være dekket"},
        {t: "Tyveri av sykkel utenfor hjemmet",c: "Må være dekket for 25 000 kr"},
        {t: "Napping av veske",c: "Må være dekket for 30 000 kr"},
        {t: "Plutselig og uforutsett hendelse utenfor boligen",c: "Må være dekket for 100 000 kr"},
        {t: "Lagringsskader pga. flytting/arbeid/skade på bolig/tyveri",c: "Må være dekket"},
        {t: "Flytting skader på innbo",c: "Må være dekket"},
        {t: "ID-tyveri juridisk bistand / tvister forsikringssum",c: "Må være dekket for 100 000 kr"},
        {t: "Dekning av nødvendige utgifter etter skade ved flytting",c: "Må være dekket for 200 000 kr"},
        {t: "Dekning av nødvendige utgifter etter skade ved lagring",c: "Må være dekket for 200 000 kr"},
        {t: "Bekjempelse av veggedyr, skjeggkre og kakerlakker",c: "Må være dekket for 100 000 kr"}
      ]
    }}
    Stars4={{
      need: "For deg som vil ha en veldig god innboforsikring, men ikke trenger de aller høyeste dekningssummene i markedet",
      description: 'Veldig gode innboforsikringer - 4 defaqto stjerner',
      terms: [
        {t: "Tyveri og skadeverk på innboet i bod",c: "Må være dekket"},
        {t: "Tyveri av sykkel utenfor hjemmet",c: "Må være dekket for 10 000 kr"},
        {t: "Napping av veske",c: "Må være dekket for 20 000 kr"},
        {t: "Lagringsskader pga. flytting/arbeid/skade på bolig/tyveri",c: "Må være dekket"},
        {t: "Flytting skader på innbo",c: "Må være dekket"},
        {t: "Dekning av nødvendige utgifter etter skade ved flytting",c: "Må være dekket for 100 000 kr"},
        {t: "Dekning av nødvendige utgifter etter skade ved lagring",c: "Må være dekket for 100 000 kr"},
        {t: "Bekjempelse av veggedyr, skjeggkre og kakerlakker",c: "Må være dekket for 50 000 kr"}
      ]
    }}
    Stars3={{
      need: "For deg som trenger en standard innboforsikring, uten markedets høyest dekningssummer",
      description: 'Gode innboforsikringer - 3 defaqto stjerner',
      terms: [
      ]
    }}
  />
)

export default IndexPage